import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import * as homeCardStyles from "./home-card.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

export default function HomeCards({ imageData, title, description, to }) {
  return (
    <div className={homeCardStyles.homeCard}>
      <Link to={to} className={homeCardStyles.imgLink}>
        <Image fluid={imageData} className={homeCardStyles.homeCardImg} />
      </Link>
      <h3>{title}</h3>
      <p>{description}</p>
      <Link to={to} className={homeCardStyles.learnMore}>
        Learn More <FontAwesomeIcon icon={faChevronRight} />
      </Link>
    </div>
  )
}
