import React from "react"
import * as pledgeBannerStyles from "./pledge-banner.module.css"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

export default function PledgeBanner() {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "images/fridge.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const bgImageData = data.background.childImageSharp.fluid
  return (
    <BackgroundImage
      Tag="section"
      className={pledgeBannerStyles.root}
      fluid={bgImageData}
    >
      <div className={pledgeBannerStyles.content}>
        <h2>Quartz Corporation</h2>
        <p>
          At Quartz, we advance technology to the point of Simplicity. Creating
          innovation that's forward thinking for what we choose to put in it –
          and what we don't. Great design that fits seamlessly into life. And is
          truly a pleasure to live with.
        </p>
      </div>
    </BackgroundImage>
  )
}
