import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as homeCardsStyles from "./home-cards.module.css"
import HomeCard from "./home-card"

export default function HomeCards() {
  const data = useStaticQuery(graphql`
    query {
      card1: file(relativePath: { eq: "images/card1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      card2: file(relativePath: { eq: "images/card2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      card3: file(relativePath: { eq: "images/card3.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      card4: file(relativePath: { eq: "images/card4.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const card1ImageData = data.card1.childImageSharp.fluid
  const card2ImageData = data.card2.childImageSharp.fluid
  const card3ImageData = data.card3.childImageSharp.fluid
  const card4ImageData = data.card4.childImageSharp.fluid
  return (
    <section className={homeCardsStyles.homeCards}>
      <HomeCard
        imageData={card1ImageData}
        title="Automatic Washing Machine"
        description="New intelligent washing experience"
        to="/washing-machines"
      />
      <HomeCard
        imageData={card2ImageData}
        title="Refrigerator"
        description="Guarding every second of freshness"
        to="/refrigerators"
      />
      <HomeCard
        imageData={card3ImageData}
        title="Kitchen Appliances"
        description="Handle whatever the day brings"
        to="/kitchen-appliances"
      />
      <HomeCard
        imageData={card4ImageData}
        title="Semi-Automatic Washing Machine"
        description="Budget friendly washing experience"
        to="/semi-auto"
      />
    </section>
  )
}
