import React from "react"
import * as homeBanner2Styles from "./home-banner2.module.css"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

export default function HomeBanner2() {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "images/banner2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const bannerImageData = data.banner.childImageSharp.fluid
  const bannerFluidImageStack = [
    "linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.2) 37%, rgba(255, 255, 255, 0.7) 100%)",
    bannerImageData,
  ]
  return (
    <BackgroundImage
      Tag={"section"}
      className={homeBanner2Styles.root}
      fluid={bannerFluidImageStack}
    >
      <div className={homeBanner2Styles.content}>
        <h2>2.8 cu. ft. Smart All-In-One Washer & Dryer</h2>
        <p>
          Reinvent your laundry routine with the 24" Smart All-In-One Washer &
          Dryer. This compact two-in-one combo fits in small spaces to replace
          an apartment washer and dryer. The ventless design allows installation
          virtually anywhere. You can even do laundry from your smartphone, and
          the Load & Go™ Plus dispenser lets you skip detergentand fabric
          softener refills for 20 loads.
        </p>
      </div>
    </BackgroundImage>
  )
}
