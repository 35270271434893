import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import * as indexStyles from "./index.module.css"
import HomeBanner2 from "../components/home-banner2"
import PledgeBanner from "../components/pledge-banner"
import HomeCards from "../components/home-cards"

export const query = graphql`
  query {
    bannerBackground: file(relativePath: { eq: "images/banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default function Index({ data }) {
  const bannerImageData = data.bannerBackground.childImageSharp.fluid
  const bannerFluidImageStack = [
    "radial-gradient(at center center, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 37%, rgba(255, 255, 255, 0.7) 100%)",
    bannerImageData,
  ]
  return (
    <Layout>
      <Helmet>
        <title>Quartz Official Homepage</title>
        <meta property="og:title" content="Quartz Official" />
        <meta property="og:description" content="At Quartz, we advance technology to the point of Simplicity." />
      </Helmet>
      <BackgroundImage
        Tag={`header`}
        className={indexStyles.showcase}
        fluid={bannerFluidImageStack}
      />
      <HomeCards />
      <HomeBanner2 />
      <PledgeBanner />
    </Layout>
  )
}
